import React from 'react';
import Theme from '@kyruus/ui-theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { defineMessages, FormattedMessage } from '@kyruus/intl';
import { Link } from 'react-router-dom';
import Button, { ButtonLink } from '@kyruus/button';

import LoadingOverlay from '../../shared/loading';
import {
  logClearFiltersThunk,
  getClearFiltersSearch
} from '../../shared/search-summary';
import { TRACKING_EVENTS } from '../filter-bar/constants';
import { FacetPanel } from '../facet-panel';
import { SideSheet } from '../SideSheet';
import { Header, Footer } from './styles';

const messages = defineMessages({
  searchFilterDrawerTitle: {
    id: 'search.filter_drawer.title',
    description: 'search filter drawer title',
    defaultMessage: 'Sort and Filter'
  },
  searchFilterDrawerViewResults: {
    id: 'search.filter_drawer.view_results',
    description: 'search filter drawer view results',
    defaultMessage: 'View Results'
  },
  clearButton: {
    id: 'search.filter_drawer.clear_button',
    description: 'text for the clear button',
    defaultMessage: 'Clear all'
  }
});

/**
 * Renders the side drawer filter panel component.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onClose - Function to close the side drawer.
 * @param {boolean} props.showMobileFacets - Flag indicating whether to show mobile facets.
 * @param {string} props.searchSummary - The search summary.
 * @param {Array} props.sortOptions - The sort options.
 * @param {number} props.totalProviders - The total number of providers.
 * @param {Object} props.log - The log object.
 * @param {Function} props.getUpdatedSearch - Function to get updated search.
 * @param {Function} props.toggleMobileFacets - Function to toggle mobile facets.
 * @param {Object} props.facets - The facets object.
 * @param {boolean} props.distanceSort - Flag indicating whether to sort by distance.
 * @param {Object} props.config - The configuration object.
 * @param {Object} props.history - The history object.
 * @param {Object} props.tokens - The tokens object.
 * @param {string} props.mapSearchUrl - The map search URL.
 * @returns {JSX.Element} The rendered SideDrawerFilterPanel component.
 */
export const SideDrawerFilterPanel = ({
  config,
  distanceSort,
  facets,
  getUpdatedSearch,
  history,
  loading,
  log,
  mapSearchUrl,
  onClose,
  searchSummary,
  showMobileFacets,
  sortOptions,
  toggleMobileFacets,
  tokens,
  totalProviders
}) => {
  const isScreenMediumOrBelow = useMediaQuery(
    `(max-width:${Theme.screen_medium})`
  );

  const handleCloseDrawer = () => {
    onClose();
    log(TRACKING_EVENTS.ALL_FILTERS_CLOSE_DRAWER);
  };

  const handleClickViewResults = () => {
    onClose();
    log(TRACKING_EVENTS.FILTER_PANEL_VIEW_RESULTS);
  };
  const hasSearchParamsApplied =
    searchSummary.has_filters ||
    searchSummary.filter?.length ||
    searchSummary.location;

  const DrawerHeader = (
    <Header id="search-filter-drawer-title">
      <FormattedMessage {...messages.searchFilterDrawerTitle} />
    </Header>
  );
  const DrawerFooter = (
    <Footer>
      {hasSearchParamsApplied && (
        <Link
          to={getClearFiltersSearch(getUpdatedSearch)}
          id="clear-filters"
          className="clear-filters"
          data-testid="clear-filters-button"
          onClick={logClearFiltersThunk(log)}
          component={ButtonLink}
        >
          <FormattedMessage {...messages.clearButton} />
        </Link>
      )}
      <Button
        data-testid="searchFilterDrawerViewResults"
        mode="primary"
        onClick={handleClickViewResults}
      >
        <FormattedMessage {...messages.searchFilterDrawerViewResults} />
      </Button>
    </Footer>
  );
  return (
    <SideSheet
      ariaLabelledBy="search-filter-drawer-title"
      dataTestId="SearchFilterDrawer"
      footer={DrawerFooter}
      header={DrawerHeader}
      id="search-filter-drawer"
      onClose={handleCloseDrawer}
      width={isScreenMediumOrBelow ? '100vw' : '550px'}
    >
      <LoadingOverlay loading={loading} />
      <FacetPanel
        config={config}
        distanceSort={distanceSort}
        facets={facets}
        getUpdatedSearch={getUpdatedSearch}
        history={history}
        isScreenMediumOrBelow={isScreenMediumOrBelow}
        log={log}
        mapSearchUrl={mapSearchUrl}
        searchSummary={searchSummary}
        showFiltersInDrawer={true} // showFiltersInDrawer is a temporary prop to show filter in drawer
        showLocationFacet={true}
        showMobileFacets={showMobileFacets}
        sortOptions={sortOptions}
        toggleMobileFacets={toggleMobileFacets}
        tokens={tokens}
        totalProviders={totalProviders}
      />
    </SideSheet>
  );
};
